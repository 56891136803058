<template>
  <div class="timeline">
    <div class="item" v-for="(item,index) of schedule" :key="item.travelId">
<!--    <div class="item" v-for="(item) of schedule" :key="item.travelId">-->
      <div class="node"></div>
      <div class="tail"></div>
      <div class="title b">第{{index+1}}天</div>
      <div class="content">
        <div class="b">{{item.title}}</div>
        <div class="m-t" v-html="formatTextareaContent(item.content)"></div>
        <div class="cl">
          <div class="sub-item" v-if="item.traffic">
            <span class="b">{{$t('Traffic')}}:</span>{{item.traffic}}
          </div>
          <div class="sub-item" v-if="item.room">
            <span class="b">{{$t('Lodging')}}:</span>{{item.room}}
          </div>
          <div class="sub-item" v-if="item.breakfast">
            <span class="b">{{$t('Breakfast')}}:</span>{{item.breakfast}}
          </div>
          <div class="sub-item" v-if="item.chineseFood">
            <span class="b">{{$t('Lunch')}}:</span>{{item.chineseFood}}
          </div>
          <div class="sub-item" v-if="item.dinner">
            <span class="b">{{$t('Dinner')}}:</span>{{item.dinner}}
          </div>
          <div class="sub-item" v-if="item.other">
            <span class="b">{{$t('Other')}}</span>:{{item.other}}
          </div>
        </div>
        <Photoswipe class="img-list" :options="options" v-if="item.imgList">
          <img class="img-item" v-for="src in item.imgList" :src="src" v-pswp="src" :key="src">
        </Photoswipe>
      </div>
    </div>
  </div>
</template>
<script>
  import textarea from '../../../mixins/textarea';

  export default {
    name: 'Timeline',
    props: {
      list: Array
    },
    data() {
      return {
        options: {bgOpacity: 0.8, shareEl: false}
      };
    },
    computed: {
      schedule: function () {
        return this.list.map(item => {
          if (item.resourceUrl) {
            item.imgList = item.resourceUrl.split(';');
          }
          return item;
        });
      }
    },
    mixins: [textarea]
  };
</script>
<style scoped lang="less">
  .timeline{margin-bottom:-20px;}
  .item{
    position:relative;padding-bottom:20px;
    .m-t{margin-top:8px;}
    &:last-child{
      .tail{display:none;}
    }
    .node{position:absolute;top:4px;left:0;z-index:1;width:10px;height:10px;background-color:#191919;border-radius:50%;}
    .tail{position:absolute;top:4px;left:5px;bottom:-4px;width:1px;background-color:#988c83;}
    .title{float:left;margin-left:20px;}
    .content{margin-left:68px;text-align:justify;}
  }
  .sub-item{float:left;width:50%;margin-top:8px;padding-right:8px;}
  .img-item{width:150px;height:100px;margin-top:8px;margin-right:8px;object-fit:cover;}
</style>
