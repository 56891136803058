<template>
  <div class="item">
    <div class="avatar">
      <img class="img" :src="datas.photoUrl" alt=""/>
    </div>
    <div class="infos">
      <div class="cl">
        <div class="fl name">{{datas.nickName}}</div>
        <star class="fl" :value="`${datas.score*100/5}%`"/>
      </div>
      <!--<div class="labels">
        <span>情侣夫妻</span>
        <span>旅游达人</span>
        <span>美食专家</span>
      </div>-->
      <div class="cont-txt">{{datas.title}}</div>
      <div class="cont-txt">{{datas.content}}</div>
      <Photoswipe class="imgs" :options="{bgOpacity: 0.8, shareEl: false}" v-if="datas.resources">
        <img class="img-item" v-for="item of datas.resources" :src="item.resourceUrl" v-pswp="item.resourceUrl"
             :key="item.resourceId">
      </Photoswipe>
      <div class="mt10 cl">
        <!--<div class="fl tag">描述相符</div>
        <div class="fl tag">描述相符</div>-->
        <div class="fr date">{{datas.addDate|formatDate}}</div>
      </div>
    </div>
  </div>
</template>
<script>
  import Star from './Star';

  export default {
    name: 'CommentItem',
    props: {
      datas: Object
    },
    components: {Star}
  };
</script>
<style scoped lang="less">
  .item{
    position:relative;min-height:48px;margin-bottom:20px;
    .avatar{position:absolute;top:0;left:0;width:48px;height:48px;overflow:hidden;border-radius:50%;}
    .infos{margin-left:58px;}
    .name{padding-right:8px;color:#595959;}
    .labels{
      margin-top:4px;
      span{display:inline-block;padding-right:20px;color:#999;}
    }
    .cont-txt{margin-top:10px;text-align:justify;color:#666;}
    .imgs{margin-top:6px;overflow:hidden;}
    .img-item{display:block;float:left;width:60px;height:60px;margin-top:4px;margin-right:4px;object-fit:cover;}
    .mt10{margin-top:10px;}
    .date{color:#999;}
    .tag{display:block;float:left;margin-right:4px;padding:0 10px;line-height:20px;color:#999;font-size:12px;background-color:#f4f4f4;border-radius:4px;}
  }
</style>
