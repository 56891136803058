<template>
  <div class="pictures-popup" @click="onClose" v-if="isShow">
    <div class="popup-content" :style="contentStyle" @click.stop>
      <img class="img" :src="currentItem.photoUrl"/>
      <div class="prev" @click="onPrev" v-if="index>0"></div>
      <div class="next" @click="onNext" v-if="index<list.length-1"></div>
      <div class="txts">
        <div class="title">{{currentItem.title}}</div>
        <div class="introduction">{{currentItem.introduction}}</div>
      </div>
      <a class="btn-close" @click="onClose"></a>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'PicturesPopup',
    props: {
      isShow: Boolean,
      list: Array,
      index: Number
    },
    data() {
      return {
        screeHeight: 0
      };
    },
    computed: {
      currentItem: function () {
        return this.list[this.index] || {};
      },
      contentStyle: function () {
        return {width: 80 + '%', height: 70 + '%'};
        // const height = this.screeHeight - 100;
        // return {width: height / 0.65 + 'px', height: height + 'px'};
      }
    },
    created() {
      this.getScreeHeight();
      window.onresize = () => {
        this.getScreeHeight();
      };
      document.addEventListener('keyup', this.handleKeyup);
    },
    destroyed() {
      window.onresize = null;
      document.removeEventListener('keyup', this.handleKeyup);
    },
    methods: {
      // 获取屏幕高度
      getScreeHeight() {
        this.screeHeight = document.body.clientHeight;
      },
      // 关闭
      onClose() {
        this.$emit('update:isShow', false);
      },
      onPrev() {
        this.$emit('update:index', this.index - 1);
      },
      onNext() {
        this.$emit('update:index', this.index + 1);
      },
      handleKeyup(e) {
        if (e.keyCode === 27) {
          this.onClose();
        } else if (e.keyCode === 37) {
          if (this.index > 0) {
            this.onPrev();
          }
        } else if (e.keyCode === 39) {
          if (this.index < this.list.length - 1) {
            this.onNext();
          }
        }
      }
    }
  };
</script>
<style scoped lang="less">
  .pictures-popup{
    position:fixed;top:0;right:0;bottom:0;left:0;z-index:2000;background-color:rgba(0, 0, 0, 0.9);user-select:none;
    .btn-close{position:absolute;top:-8px;right:-8px;width:20px;height:20px;cursor:pointer;background:#fff url(../../../assets/images/icon/close-circle.png) no-repeat 0 0;border-radius:50%;}
  }
  .popup-content{
    position:absolute;top:50%;left:50%;padding:5px;background-color:#fff;transform:translateX(-50%) translateY(-50%);border-radius:4px;
    .txts{position:absolute;right:5px;bottom:5px;left:5px;z-index:1;padding:10px;line-height:20px;color:#fff;background-color:rgba(0, 0, 0, 0.6)}
    .introduction{opacity:0.9;}
    .prev{position:absolute;top:0;left:0;width:50%;height:100%;cursor:url(../../../assets/images/cursor_left.png), auto;}
    .next{position:absolute;top:0;right:0;width:50%;height:100%;cursor:url(../../../assets/images/cursor_right.png), auto;}
  }
</style>
