<template>
  <div id="goBooking" class="go-booking">
    <div class="form">
      <div class="form-item">
        <div class="label">{{$t('DepartureDate')}}：</div>
        <div class="value">
          <el-date-picker class="select" type="date" size="small"
                          :picker-options="pickerOptions"
                          value-format="yyyy-MM-dd"
                          :clearable="false"
                          :placeholder="$t('PleaseChoose')"
                          @change="this.changeDepartureDate"
                          v-model="departureDate">
          </el-date-picker>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{$t('Route')}}：</div>
        <div class="value">
          <el-select class="select" v-model="lineId" size="small" :disabled="!departureDate"
                     :placeholder="$t('PleaseChoose')">
            <el-option v-for="item in lines"
                       :key="item.lineId"
                       :label="item.lineTitle"
                       :value="item.lineId"
                       :disabled="item.lastPeopleNumber<=0">
              <span style="float:left">{{item.lineTitle}}</span>
              <span style="float:right;color:#c0c4cc;font-size:12px">{{item.memo}}</span>
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{$t(currentPrice.priceType!=='1'?'Adult':'Passenger')}}：</div>
<!--        -->
        <div class="value" v-if="specialProduct">
          <el-select class="select" size="small" :placeholder="$t('PleaseChoose')"
                     :disabled="currentPrice.isAdult!=='Y'"
                     @change="calcNumofPeople"
                     v-model="adultNumber">
            <el-option v-for="i in 4"
                       :key="i"
                       :label="i"
                       :value="i"
                       :disabled="i<minAdultNumber">
            </el-option>
          </el-select>
        </div>
<!--        -->
        <div class="value" v-else>
          <el-select class="select" size="small" :placeholder="$t('PleaseChoose')"
                     :disabled="currentPrice.isAdult!=='Y'"
                     v-model="adultNumber">
            <el-option key="0" label="0" value="0"></el-option>
            <el-option v-for="i in 100"
                       :key="i"
                       :label="i"
                       :value="i"
                       :disabled="i<minAdultNumber">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="form-item" v-if="isTicket === 'Y' && !specialProduct">
        <div class="label">{{$t(currentPrice.priceType!=='1'?('Elderly'):'Passenger')}}：</div>
        <div class="value">
          <el-select class="select" size="small" :placeholder="$t('PleaseChoose')"
                     :disabled="currentPrice.isElderly!=='Y'"
                     v-model="elderlyNumber">
            <el-option key="0" label="0" value="0"></el-option>
            <el-option v-for="i in 100"
                       :key="i"
                       :label="i"
                       :value="i">
            </el-option>
          </el-select>
        </div>
      </div>
<!--      -->
      <template v-if="currentPrice.priceType!=='1' && specialProduct">
        <div class="form-item">
          <div class="label">{{$t('Aged under 3 and not occupying seats')}})：</div>
          <div class="value">
            <el-select class="select" size="small" :placeholder="$t('PleaseChoose')"
                       :disabled="currentPrice.isChild!=='Y'"
                       @change="calcNumofPeople"
                       v-model="childNumber">
              <el-option v-for="i in 1"
                         :disabled="adultNumber===4"
                         :key="i"
                         :label="i"
                         :value="i"></el-option>
            </el-select>
          </div>
        </div>
      </template>
<!--      -->
      <template v-if="currentPrice.priceType!=='1' && !specialProduct">
        <div class="form-item">
          <div class="label">{{$t('Child')}}(2-11{{$t('YearOld')}})：</div>
          <div class="value">
            <el-select class="select" size="small" :placeholder="$t('PleaseChoose')"
                       :disabled="currentPrice.isChild!=='Y'"
                       v-model="childNumber">
              <el-option v-for="item of childOptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="form-item">
          <div class="label">{{$t('Baby')}}(0-1{{$t('YearOld')}})：</div>
          <div class="value">
            <el-select class="select" size="small" :placeholder="$t('PleaseChoose')"
                       :disabled="currentPrice.isBaby!=='Y'"
                       v-model="babyNumber">
              <el-option v-for="item of babyOptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </template>
      <div class="form-item" v-if="isDay===0">
        <div class="label">{{$t('NumberOfRooms')}}：</div>
        <div class="value">
          <el-select class="select" size="small" :placeholder="$t('PleaseChoose')"
                     :disabled="currentPrice.isSingleBed!=='Y'"
                     v-model="roomsNumber">
            <el-option v-for="item of roomsOptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="form-item" v-if="currentPrice.insurance&&false">
        <div class="label insurance">{{$t('Insurance arrangement')}}：</div>
        <div class="value">
          <div>
            <radio v-model="isInsurance" label="Y">
              <i18n path="Purchase insurance">
                <a class="link" target="_blank" :href="currentPrice.insurance.fileUrl">
                  {{$t('Platform Select Insurance')}}
                </a>
              </i18n>
            </radio>
          </div>
          <div class="insurance-price">
            <div class="item">
              {{$t(currentPrice.priceType!=='1'?'Adult':'Per person')}}：
              {{currentPrice.billingType}} {{currentPrice.insurance.adultPrice}}
            </div>
            <div class="item" v-if="currentPrice.priceType!=='1'">
              {{$t('Child')}}/{{$t('Baby')}}：{{currentPrice.billingType}} {{currentPrice.insurance.childPrice}}
            </div>
          </div>
          <div>
            <radio v-model="isInsurance" label="N">{{$t('Self-arranged insurance')}}</radio>
          </div>
        </div>
      </div>
      <!--總金額-->
      <charge-details-popover :current-price="currentPrice"
                              :room-number-detail="roomNumberDetail"
                              :adult-number="adultNumber"
                              :elderly-number="elderlyNumber"
                              :child-number="childNumber"
                              :baby-number="babyNumber"
                              :total-price="totalPrice"
                              :is-insurance="isInsurance"
                              :is-ticket="isTicket"/>
      <a class="btn-submit" :disabled="disableSubmit" @click="onSubmit">{{$t('BookNow')}}</a>
    </div>
  </div>
</template>
<script>
  import {mapGetters} from 'vuex';
  import Radio from '../../../components/Radio';
  import ChargeDetailsPopover from '../../../components/ChargeDetailsPopover';
  import numberOptions from '../../../mixins/numberOptions';
  import {currencySymbol} from '../../../utils';
  import {getProductPrice} from '../../../api/product';
  import {orderBooking} from '../../../api/order';

  export default {
    name: 'OrderBox',
    props: {
      value: String,
      productId: String,
      prices: Array,
      pageLineId: String,
      isDay: Number,
      isTicket: String
    },
    data() {
      return {
        lineId: '',
        departureDate: '',
        currentPrice: {},
        isInsurance: '',
        specialProduct: false
      };
    },
    created() {
      if (this.productId === 'cc43529fcac24064a7b6d68c066aae93') {
        this.specialProduct = true;
      }
    },
    computed: {
      ...mapGetters(['token']),
      // 当前日期的路线
      lines: function () {
        const lines = (this.prices.find(item => item.priceDate === this.departureDate) || {}).priceDateStatuses || [];
        this.setDefaultLineId(lines);
        return lines;
      },
      disableSubmit: function () {
        return !this.departureDate || !this.lineId;
      },
      priceDates: function () {
        return this.prices.map(item => {
          if (item.isTopUp === 'N') {
            return new Date(item.priceDate.replace(/-/g, '/')).getTime();
          } else {
            return '';
          }
        });
      },
      pickerOptions: function () {
        return {
          disabledDate: (time) => {
            const timestamp = time.getTime();
            return !this.priceDates.includes(timestamp);
          }
        };
      }
    },
    components: {ChargeDetailsPopover, Radio},
    mixins: [numberOptions],
    methods: {
      calcNumofPeople(){
        if (this.adultNumber === 4) {
          this.childNumber = 0;
        }
      },
      setDefaultLineId(lines) {
        if (lines.length) {
          if (!lines.find(item => item.lineId === this.lineId && item.lastPeopleNumber > 0)) {
            this.lineId = (lines.find(item => item.lastPeopleNumber > 0) || {}).lineId || '';
          }
        } else {
          this.lineId = '';
        }
      },
      // 修改出发日期的回调
      changeDepartureDate(date) {
        this.$emit('input', date);
      },
      // 按日期及路线获取价格详情
      getProductPrice() {
        if (this.lineId && this.departureDate) {
          getProductPrice(this.lineId, this.departureDate).then(res => {
            let currentPrice = res.value || {};
            currentPrice.billingType = currencySymbol(currentPrice.billingType);
            currentPrice.adultPrice = (currentPrice.adultPrice || 0) * 100;
            currentPrice.elderlyPrice = (currentPrice.elderlyPrice || 0) * 100;
            currentPrice.childPrice = (currentPrice.childPrice || 0) * 100;
            currentPrice.notChildPrice = (currentPrice.notChildPrice || 0) * 100;
            currentPrice.babyPrice = (currentPrice.babyPrice || 0) * 100;
            currentPrice.singleBedPrice = (currentPrice.singleBedPrice || 0) * 100;
            this.currentPrice = currentPrice;
            if (currentPrice.priceType === '1') {
              this.childNumber = 0;
              this.babyNumber = 0;
            }
          });
        } else {
          this.currentPrice = {};
          this.childNumber = 0;
          this.babyNumber = 0;
        }
      },
      // 预定
      onSubmit() {
        const data = {
          adultNumber: this.adultNumber, // 成人数
          elderlyNumber: this.elderlyNumber, // 長者人數
          childNumber: this.childNumber, // 小童数
          babyNumber: this.babyNumber, // 婴儿数
          roomsNumber: this.roomsNumber, // 房间数量
          productId: this.productId,
          lineId: this.lineId,
          priceDate: this.departureDate,
          totalMoney: this.totalPrice,
          languageType: this.$i18n.locale,
          orderSource: 'pc', // 移动端h5
          isInsurance: this.isInsurance || 'N' // 是否保险
        };

        if (parseInt(this.adultNumber) + parseInt(this.elderlyNumber) + parseInt(this.childNumber) + parseInt(this.babyNumber) === 0){
          this.$message.warning(this.$t('Please choose the number of people'));
          return;
        } else {
          console.log(this.adultNumber + this.elderlyNumber + this.childNumber + this.babyNumber);
        }

        if (!this.token) {
          const routeUrl = this.$router.resolve({path: this.$route.fullPath, query: {formdata: JSON.stringify(data)}});
          this.$message.warning(this.$t('Please sign in'));
          this.$router.push({name: 'Login', query: {redirect: routeUrl.href}});
        } else {
          const loading = this.$loading({text: 'Loading'});
          orderBooking(data).then(res => {
            const orderId = res.value.orderId;
            console.log(`Checkpoing: the orderId is ${{orderId}}`);
            loading.close();
            this.$router.push({name: 'ConfirmOrder', query: {orderId}});
            // {name: 'ConfirmOrder',query:{departureDate,lineId,adultNumber,childNumber,babyNumber,roomsNumber}}
          }).catch(() => {
            loading.close();
          });
        }
      }
    },
    watch: {
      value: function (value) {
        this.departureDate = value;
      },
      lineId: function (value) {
        if (value) {
          this.$emit('update:pageLineId', value);
        }
        this.getProductPrice();
      },
      departureDate: function () {
        this.getProductPrice();
      }
    }
  };
</script>
<style scoped lang="less">
  .go-booking{}
  .form{
    padding-top:60px;margin-left: 10px;
    background-color:#fff;
    .btn-submit{display:block;margin-top:30px;line-height:66px;text-align:center;color:#fff;font-size:24px;border-radius:4px;background-image:linear-gradient(45deg, #fad7a1 0%, #fe6f60 100%);cursor:pointer;}
  }
  .form-item{
    display:flex;align-items:center;margin-bottom:15px;
    .label{
      padding-right:10px;
      &.insurance{align-self:flex-start;line-height:26px;}
    }
    .value{flex:1;}
    .select{width:100%;}
    .link{text-decoration:underline;color:#ff6f61;}
    /deep/ .radio .label{color:#321908;}
  }
  .insurance-price{
    padding-bottom:20px;font-size:12px;
    .item{margin-top:5px;color:#666;}
  }
</style>
