<template>
  <div class="main">
    <div class="layout">
      <el-row>
       <div class="cl">
         <el-col :span="12" :xs="24">
          <breadcrumb class="fl">
          <breadcrumb-item :to="{name:'Home'}">{{$t('Home')}}</breadcrumb-item>
          <breadcrumb-item :to="{name:'AdminBusinessDetail', params:{id:business.userId}}" v-if="business.businessName">
            {{business.businessName}}
          </breadcrumb-item>
          <breadcrumb-item>{{product.title}}</breadcrumb-item>
          </breadcrumb>
         </el-col>
         <el-col :span="12" :xs="24" >
            <div class="tour-number">{{$t('Tour group number')}}：{{product.productCode}}</div>
         </el-col>
       </div>
      </el-row>
      <pictures/>
      <h1 class="h1">{{product.title}}</h1>
      <div class="rich-text content cl" v-html="formatTextareaContent(product.introduction)"></div>
      <div class="container">
        <el-row :gutter="10">
          <div class="cl">
            <el-col :span="16" :xs="24">
            <div class="fl-group calendar-wrap">
              <div class="hd">{{$t('SelectDate')}}</div>
              <div class="bd">
                <calendar :prices="prices" :lineId="lineId" v-model="departureDate"/>
              </div>
            </div>
            </el-col>
            <el-col :span="8" :xs="24">
                      <order-box :product-id="productId"
                       :prices="prices"
                       :page-line-id.sync="lineId"
                       :isDay="isDay"
                       :isTicket="isTicket"
                       v-model="departureDate"/>
            </el-col>
          </div>
          <div class="whatsapp" style="z-index: 100">
              <div class="p" v-for="item of business.contacts" :key="item.businessId">
          <div v-if="item.phoneType =='WhatsApp'">
            <a :href='getURL("https://wa.me/852"+item.userPhone)'><img
          src="../../assets/images/icon/whatsAppContact.png"
          style="width: 60px; height: 60px;position:fixed;top:500px;z-index:9999"
        /></a>
          </div>
        </div>
    </div>
        </el-row>
        <!--路线-->
        <div class="fl-group hairline">
          <div class="hd">{{$t('Route')}}</div>
          <div class="bd">
            <div class="tourist-route">
              <a class="item" href="javascript:;"
                 v-for="item of productLines"
                 :class="{active:item.lineId===lineId}"
                 :title="item.lineTitle"
                 @click="lineId = item.lineId"
                 :key="item.lineId">
                <div class="letter">{{item.lineName}}</div>
                <div class="line-title">{{item.lineTitle}}</div>
              </a>
            </div>
          </div>
        </div>
        <!-- 航班資料 -->
        <div class="flight-list" v-if="isFlight===1&&flightList.length">
          <div class="tit">{{$t('Flight information')}}</div>
          <div class="infos">
            <div v-for="item of flightList" :key="item.flightId">
              <template v-if="item.isCustomize==='N'">
                <div class="item" v-for="fItem of item.flightDetails" :key="fItem.outsetDate">
                  <span class="spacing stage">{{$t('No.Schedule',[fItem.stage])}}</span>
                  <span>{{fItem.departure}}</span>
                  <span class="svg-icon-wrap">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="18" height="18"
                           viewBox="0 0 24 24">
                        <path d="M22 12l-4-4v3H3v2h15v3z"></path>
                      </svg>
                    </span>
                  <span class="spacing">{{fItem.destination}}</span>
                  <span class="spacing">{{fItem.airline}} {{fItem.airlineNumber}}</span>
                  <span>HKT {{fItem.outsetTime}}</span>
                  <span class="svg-icon-wrap">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="18" height="18"
                           viewBox="0 0 24 24">
                        <path d="M22 12l-4-4v3H3v2h15v3z"></path>
                      </svg>
                    </span>
                  <span>HKT {{fItem.arrivalsTime}}</span>
                </div>
              </template>
              <div class="rich-text" v-html="item.customizeContent" v-else-if="item.isCustomize==='Y'"></div>
            </div>
          </div>
        </div>
        <div id="tabsNav" class="tabs-nav" :class="fixedNav" :style="{minHeight:tabsNavHeight+'px'}">
          <div class="inner" id="innerNav">
            <a v-for="item of programs"
               :class="{active:currentTabs===item.programLabel}"
               :key="item.programLabel"
               @click="goAnchor(item.programLabel)"
            >
<!--               @click="goAnchor(item.programLabel)">-->
              {{item.programName}}
            </a>
            <a :class="{active:currentTabs==='UserEvaluation'}" @click="goAnchor('UserEvaluation')">
              {{$t('UserEvaluation')}}
            </a>
          </div>
        </div>
        <!--产品介绍-->
        <div id="introduction" class="fl-group navigation" v-waypoint="{active: true, callback: onWaypoint, option: intersectionOptions }">
          <div class="hd">{{$t('ProductDescription')}}</div>
          <div class="bd rich-text" v-html="(introduction||{}).introduction"></div>
        </div>
        <!--行程表-->
        <div id="travel" class="fl-group navigation"
             v-if="programLabels.includes('travel')"
             v-waypoint="{active: true, callback: onWaypoint, option: intersectionOptions }">
          <div class="hd">{{$t('Schedule')}}</div>
          <div class="bd" v-if="travels">
            <timeline :list="travels"/>
          </div>
        </div>
        <!--费用细节-->
        <div id="cost" class="fl-group navigation"
             v-if="programLabels.includes('cost')"
             v-waypoint="{active: true, callback: onWaypoint, option: intersectionOptions }">
          <div class="hd">{{$t('CostDetails')}}</div>
          <div class="bd rich-text" v-if="cost">
            <template v-if="cost&&cost.includeContent">
              <div class="b">{{$t('CostIncludes')}}：</div>
              <div class="m-t" v-html="formatTextareaContent(cost.includeContent)"></div>
            </template>
            <template v-if="cost&&cost.notIncludeContent">
              <div class="m-t b">{{$t('CostDoesNotInclude')}}：</div>
              <div class="m-t" v-html="formatTextareaContent(cost.notIncludeContent)"></div>
            </template>
          </div>
        </div>
        <!--自费项目-->
        <div id="optional" class="fl-group navigation"
             v-if="programLabels.includes('optional')"
             v-waypoint="{active: true, callback: onWaypoint, option: intersectionOptions }">
          <div class="hd">{{$t('SelfFunded')}}</div>
          <div class="bd" v-if="optional">
            <div class="rich-text" v-html="optional.content"></div>
          </div>
        </div>
        <!--预订须知-->
        <div id="booking" class="fl-group navigation"
             v-if="programLabels.includes('booking')"
             v-waypoint="{active: true, callback: onWaypoint, option: intersectionOptions }">
          <div class="hd">{{$t('BookingInstructions')}}</div>
          <div class="bd" v-if="booking">
            <div class="rich-text" v-html="booking.content"></div>
          </div>
        </div>
        <!--签证须知-->
        <div id="visa" class="fl-group navigation"
             v-if="programLabels.includes('visa')"
             v-waypoint="{active: true, callback: onWaypoint, option: intersectionOptions }">
          <div class="hd">{{$t('VisaRequirements')}}</div>
          <div class="bd">
            <tabs type="border-card" v-if="visas&&visas.length" :value="visas[0].id">
              <tab-pane v-for="item of visas" :label="item.location" :name="item.id" :key="item.id">
                <div class="rich-text" v-html="item.content"></div>
              </tab-pane>
            </tabs>
          </div>
        </div>
        <!--责任条款-->
        <div id="responsibility" class="fl-group navigation"
             v-if="programLabels.includes('responsibility')"
             v-waypoint="{active: true, callback: onWaypoint, option: intersectionOptions }">
          <div class="hd">{{$t('LiabilityClause')}}</div>
          <div class="bd" v-if="responsibility">
            <div class="rich-text" v-html="responsibility.content"></div>
          </div>
        </div>
        <!--取消政策-->
        <div id="policy" class="fl-group navigation"
             v-if="programLabels.includes('policy')"
             v-waypoint="{active: true, callback: onWaypoint, option: intersectionOptions }">
          <div class="hd">{{$t('CancellationPolicy')}}</div>
          <div class="bd" v-if="policy">
            <div class="rich-text" v-if="policy.current" v-html="policy.current.content"></div>
            <cancellation-policy-table :policy="policy.policies"/>
          </div>
        </div>
        <!--上载行程-->
        <div id="file" class="fl-group navigation"
             v-if="programLabels.includes('file')"
             v-waypoint="{active: true, callback: onWaypoint, option: intersectionOptions }">
          <div class="hd">{{$t('DownloadTrip')}}</div>
          <div class="bd" v-if="lineFile">
            <a class="button button-primary small" :href="lineFile.fileUrl" target="_blank">
              {{$t('DownloadTrip')}}
            </a>
          </div>
        </div>
        <!--自定义栏目-->
        <div class="fl-group navigation"
             v-for="item of customizeProgramsInfo"
             :id="item.programLabel"
             :key="item.programLabel"
             v-waypoint="{active: true, callback: onWaypoint, option: intersectionOptions }">
<!--          <div class="fl-group"-->
<!--             v-for="item of customizeProgramsInfo"-->
<!--             :id="item.programLabel"-->
<!--             v-waypoint="{active: true, callback: onWaypoint, option: intersectionOptions }"-->
<!--             :key="item.programLabel">-->
          <div class="hd">{{item.programName}}</div>
          <div class="bd rich-text" v-html="item.content"></div>
        </div>
        <!--用户评价-->
        <div id="UserEvaluation" class="fl-group navigation" v-waypoint="{active: true, callback: onWaypoint, option: intersectionOptions }">
          <div class="hd">{{$t('UserEvaluation')}}</div>
          <div class="bd">
            <comment-list/>
          </div>
        </div>
      </div>
      <recommended-tour :list="popularTravels" v-if="false"/>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import Photoswipe from 'vue-pswipe';
  import VueWaypoint from 'vue-waypoint';
  import {Tabs, TabPane} from 'element-ui';
  import CommentList from './components/CommentList';
  import Breadcrumb from '../../components/Breadcrumb';
  import BreadcrumbItem from '../../components/BreadcrumbItem';
  import Calendar from './components/Calendar';
  import Pictures from './components/Pictures';
  import RecommendedTour from './components/RecommendedTour';
  import Timeline from './components/Timeline';
  import OrderBox from './components/OrderBox';
  import CancellationPolicyTable from '../../components/CancellationPolicyTable';
  import textarea from '../../mixins/textarea';
  // import {getNearEle} from '../../utils';
  import {getProductDetail, getProductLineDetail, getProductFlight, getProductPriceList} from '../../api/product';
  import {orderBooking} from '../../api/order';

  Vue.use(Photoswipe);
  Vue.use(VueWaypoint);
  export default {
    name: 'TourismDetail',
    // metaInfo: {
    //   title: 'kjiuuuuj',
    //   titleTemplate: '%s | My Awesome Webapp'
    //   // meta: [
    //   //   { name: 'title', content: this.product.title },
    //   //   { name: 'description', content: this.product.introduction },
    //   //   { name: 'og:image', content: this.product.photoUrl },
    //   //   { name: 'og:image:alt', content: this.product.title },
    //   //   { name: 'og:title', content: this.product.title },
    //   //   { name: 'og:description', content: this.product.introduction }
    //   // ]
    // },
    metaInfo() {
      return {
        title: this.product.title + ' ｜ 一遊旅遊平台',
        meta: [
            { name: 'title', content: this.product.title + ' ｜ 一遊旅遊平台' },
            { name: 'description', content: this.product.introduction },
            { name: 'og:image', content: this.product.photoUrl },
            { name: 'og:image:alt', content: this.product.title },
            { name: 'og:title', content: this.product.title },
            { name: 'og:description', content: this.product.introduction }
          ]
      };
    },
    data() {
      return {
        departureDate: '',
        productId: '',
        business: {},
        product: {},
        productLines: [],
        popularTravels: [],
        lineId: '',
        programs: [],
        customizeProgramsInfo: [],
        introduction: null,
        travels: null,
        cost: null,
        optional: null,
        booking: null,
        visas: null,
        responsibility: null,
        policy: {},
        lineFile: null,
        scrollTop: 0,
        tabsNavOffsetTop: 0,
        tabsNavHeight: 0,
        currentTabs: '',
        flightList: [],
        prices: [],
        isFlight: '',
        isDay: 0,
        isTicket: '',
        fixedNav: false,
        menuYloc: 0,
        intersectionOptions: {
          root: null,
          rootMargin: '0px 0px 0px 0px',
          threshold: [0.5, 0.55] // [0.25, 0.75] if you want a 25% offset!
        }
      };
    },
    computed: {
      programLabels: function () {
        return this.programs.map(item => item.programLabel);
      }
    },
    mixins: [textarea],
    components: {
      CancellationPolicyTable,
      OrderBox,
      Tabs,
      TabPane,
      Calendar,
      Breadcrumb,
      BreadcrumbItem,
      Pictures,
      RecommendedTour,
      Timeline,
      CommentList
    },
    beforeRouteEnter(to, from, next) {
      if (to.query.formdata) {
        orderBooking(JSON.parse(to.query.formdata)).then(res => {
          const orderId = res.value.orderId;
          // console.log("Checkpoint: the order id is " + orderId);
          next({name: 'ConfirmOrder', query: {orderId}});
        }).catch(() => {
          next();
        });
      } else {
        next();
      }
    },
    created() {
      this.productId = this.$route.params.id;
      this.getDetail();

      this.$nextTick(() => {
        window.addEventListener('scroll', this.onScroll);
      });
    },
    destroyed() {
      document.querySelector('#app').removeEventListener('scroll', this.onScroll);
    },
    methods: {
      getURL(url) {
        url = JSON.stringify(url);
        url = url.replaceAll('"', '');
          return url.replace(' ', '');
      },
      onScroll() {
        this.scrollTop = document.querySelector('#app').scrollTop;
        var offset = document.documentElement.scrollTop - window.innerHeight;
        if (offset > this.menuYloc) {
          document.getElementById('innerNav').classList.add('fixedNav');
            document.getElementById('innerNav').classList.remove('nNav');
          } else {
            document.getElementById('innerNav').classList.remove('fixedNav');
            document.getElementById('innerNav').classList.add('nNav');
          }
      },
      // 锚点
      goAnchor(name) {
        this.currentTabs = name;
        const anchor = this.$el.querySelector('#' + name);
        const offsetTop = anchor.offsetTop;
        // scrollToY(undefined, offsetTop - 300);
        window.scrollTo(0, offsetTop - 150);
      },
      onWaypoint({el, going, direction}) {
        // if (going === 'in' && direction !== undefined) {
        //   console.log(el.id + ';' + going + ';' + direction);
        //   this.currentTabs = el.getAttribute('id');
        // }
        // if (this.$waypointMap.GOING_IN === going && this.$waypointMap.DIRECTION_TOP === direction) {
        //   this.currentTabs = el.getAttribute('id');
        // } else if (this.$waypointMap.GOING_OUT === going && this.$waypointMap.DIRECTION_BOTTOM === direction) {
        //   const $prev = getNearEle(document.querySelector('#' + el.getAttribute('id')), 1);
        //   if ($prev) {
        //     this.currentTabs = $prev.getAttribute('id');
        //   }
        // }
      },
      // 获取产品详情
      getDetail() {
        getProductDetail(this.productId).then(res => {
          Object.assign(this.$data, res.value || {});
          this.lineId = res.value.productLines[0].lineId;
          this.isFlight = res.value.product.isFlight;
          this.isDay = res.value.product.isDay;
          this.isTicket = res.value.product.isTicket;
        });
        // 获取产品价格列表
        getProductPriceList(this.productId).then(res => {
          let prices = res.value || [];
          // lineTitle 按A-Z排序
          prices = prices.map(item => {
            item.priceDateStatuses = item.priceDateStatuses || [];
            item.priceDateStatuses.sort((a, b) => a.lineTitle.localeCompare(b.lineTitle));
            return item;
          });
          this.prices = prices;
        });
      },
      // 获取产品路线详情
      getProductLineDetail() {
        getProductLineDetail(this.lineId).then(res => {
          const {programs, introduction, travels, cost, optional, booking, visas, responsibility, policy, lineFile, ...customizeProgramsInfo} = res.value || {};
          // const otherPrograms = programs.filter(item => !['introduction', 'travel', 'cost', 'visa', 'policy', 'file'].includes(item.programLabel));
          this.programs = programs || [];
          this.customizeProgramsInfo = customizeProgramsInfo || [];
          this.introduction = introduction || {};
          this.travels = travels || null;
          this.cost = cost || null;
          this.optional = optional || null;
          this.booking = booking || null;
          this.visas = visas || null;
          this.responsibility = responsibility || null;
          this.policy = policy || {};
          this.lineFile = lineFile || null;
          this.tabsNavHeight = 0;
          this.$nextTick(() => {
            const $tabsNav = document.querySelector('#tabsNav');
            this.tabsNavOffsetTop = $tabsNav.offsetTop;
            this.tabsNavHeight = $tabsNav.clientHeight;
          });
        }).catch(() => {
        });
      },
      // 根据路线及日期获取航班资料
      getProductFlight() {
        if (this.lineId && this.departureDate) {
          getProductFlight(this.lineId, this.departureDate).then(res => {
            this.flightList = res.value;
            this.$nextTick(() => {
              const $tabsNav = document.querySelector('#tabsNav');
              this.tabsNavOffsetTop = $tabsNav.offsetTop;
            });
          });
        } else {
          this.flightList = [];
          this.$nextTick(() => {
            const $tabsNav = document.querySelector('#tabsNav');
            this.tabsNavOffsetTop = $tabsNav.offsetTop;
          });
        }
      }
    },
    watch: {
      lineId: function () {
        this.getProductLineDetail();
        this.getProductFlight();
      },
      departureDate: function () {
        this.getProductFlight();
      }
    }
  };
</script>
<style scoped lang="less">
  .nNav{
    padding: 30px 0 0 0;
  }
  .fixedNav{
    top: 60px;
    left: 0px;
    right: 0px;
    padding: 30px 5vw 0px 5vw;
    position: fixed;
    z-index: 999;
  }
  .layout{
    padding: 0 5vw 0;
  }
  .main{
    margin-bottom:-30px;padding-bottom:30px;background-color:#f4f4f4;
    .h1{margin-top:30px;font-size:24px;}
    .content{margin:8px 0 30px;}
    .tour-number{float:right;margin-top:20px;color:#ff6f61;}
    .m-t{margin-top:8px;}
  }
  .container{padding:0 30px;overflow:hidden;background-color:#fff;}
  .calendar-wrap{margin-right:0}
  .fl-group{
    &.hairline{border-bottom:1px solid #d9d9d9;}
    .hd{
      position:relative;margin:30px 0 20px;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
    }
    .bd{padding-left:16px;}
  }
  .tourist-route{
    padding-bottom:22px;
    .item{
      display:inline-block;margin:0 8px 8px 0;padding:8px 10px;line-height:1;text-align:center;color:#fff;font-size:16px;opacity:0.5;background-color:#ff6f61;border-radius:4px;
      &:hover,
      &.active{opacity:1;transition:opacity 0.6s;}
      .line-title{max-width:160px;min-width:160px;margin-top:5px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-size:12px;}
    }
  }
  .tabs-nav{
    &.fixed{
      .inner{position:fixed;top:60px;z-index:100;}
    }
    .inner{padding-top:30px;border-bottom:1px solid #d9d9d9;background-color:#fff;}
    a{
      display:inline-block;width:auto;margin-right:10px;margin-bottom:30px;cursor:pointer;font-weight: 500;
      &:hover,
      &.active{text-decoration:none;color:#ff6f61;}
    }
  }
  .flight-list{
    display:table;width:100%;margin-top:30px;padding-bottom:30px;border-bottom:1px solid #d9d9d9;
    .tit{display:table-cell;padding-right:20px;white-space:nowrap;vertical-align:middle;font-size:20px;}
    .infos{display:table-cell;width:100%;vertical-align:middle;}
    .item{
      margin-bottom:10px;line-height:1;
      &:last-child{margin-bottom:0}
      > span{display:inline-block;vertical-align:middle;}
    }
    .spacing{margin-right:20px;}
    .svg-icon-wrap{margin:0 5px;}
  }

  @media only screen and (max-width: 766px) {
  .main .tour-number {
    float: none;
    margin-top: -10px;
    margin-bottom: 10px;
  }
}
</style>
