import request from '@/utils/request';
import {getCurrency} from '../utils';

const currency = getCurrency();

// 获取订单基本信息
export function getOrder(orderId) {
  return request({
    url: `/order/getOrder/${orderId}`,
    method: 'get'
  });
}

// 预订
export function orderBooking(data) {
  data.currencyCode = currency;
  return request({
    url: '/order/product/booking',
    method: 'post',
    data
  });
}

// 获取订单资料
export function getOrderMaterial(orderId) {
  return request({
    url: `/order/material/${orderId}`,
    method: 'get'
  });
}

// 保存订单资料
export function saveOrderMaterial(orderId, data) {
  return request({
    url: `/order/save/material/${orderId}`,
    method: 'post',
    data
  });
}

// 創建支付平台訂單單
export function orderPay(data) {
  data.currencyCode = currency;
  return request({
    url: '/order/pay',
    method: 'post',
    data
  });
}

// 查询订单支付状态
export function getOrderPayStatus(orderId) {
  return request({
    url: `/order/pay/status/${orderId}`,
    method: 'get'
  });
}

// 获取我的订单列表
export function getMyOrderList(data) {
  return request({
    url: '/order/list',
    method: 'get',
    params: data
  });
}

// 获取订单详情
export function getOrderDetail(orderId) {
  return request({
    url: `/order/all/details/${orderId}`,
    method: 'get'
  });
}

// 继续付款
export function orderContinuePay(data) {
  data.currencyCode = currency;

  return request({
    url: '/order/continue/pay',
    method: 'post',
    data
  });
}

// 修改订单状态(取消未支付的订单)
export function updateOrderStatus(data) {
  return request({
    url: '/order/update/status',
    method: 'post',
    data
  });
}

// paypal支付同步回調
export function verifyPaypal(payPalOrderId, transactionTimeDuration) {
  return request({
    url: `/pay/verify/paypal/${payPalOrderId}`,
    method: 'get',
    params: transactionTimeDuration
  });
}

// 获取退费政策
export function getRefundPolicy(orderId) {
  return request({
    url: `/cancel/order/line/policy/${orderId}`,
    method: 'get'
  });
}

// 退款预处理
export function cancelOrderPre(orderId) {
  return request({
    url: `/cancel/order/pre/${orderId}`,
    method: 'post'
  });
}

// 确认退款，修改订单状态，并生成退款订单
export function cancelOrderSure(data) {
  return request({
    url: '/cancel/order/sure',
    method: 'post',
    data
  });
}

// 获取退款详情
export function getRefundDetails(orderId) {
  return request({
    url: `/cancel/order/details/${orderId}`,
    method: 'get'
  });
}

// 获取新订单
export function getNewOrderDetails(orderId) {
  return request({
    url: `/cancel/order/new/order/${orderId}`,
    method: 'get'
  });
}

// 获取未支付订单首次发起支付时间及当前系统时间
export function getFirstPaymentTime(orderId) {
  return request({
    url: `/order/notPayment/firstPaymentTime/${orderId}`,
    method: 'get'
  });
}

// 获取订单收据
export function getOrderReceipt(orderId) {
  return request({
    url: `/order/getOrderReceipt/${orderId}`,
    method: 'get'
  });
}

// 获取订单成团通知
export function getOrderNotice(orderId) {
  return request({
    url: `/order/getOrderNotice/${orderId}`,
    method: 'get'
  });
}

// 获取产品基本信息
export function getProduct(productId) {
  return request({
    url: `/product/getProduct/${productId}`,
    method: 'get'
  });
}
