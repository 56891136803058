<template>
  <div class="recommended" v-if="list.length">
    <div class="hd">{{$t('RelatedTravelNotes')}}</div>
    <div class="item"
         v-for="item of list"
         :key="item.travelsId"
         @click="$router.push({name:'TravelsDetail',params:{id:item.travelsId}})">
      <div class="cover">
        <img class="img" :src="item.coverUrl" alt=""/>
      </div>
      <div class="title">
        <span>{{item.title}}</span>
        <span>&gt;</span>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'RecommendedTour',
    props: {
      list: Array
    }
  };
</script>
<style scoped lang="less">
  .recommended{
    .hd{padding:30px 0 20px;text-align:center;font-size:24px;}
    .item{
      height:260px;margin-bottom:20px;padding-top:5px;cursor:pointer;background-color:#fff;border-radius:4px;
      &:hover{
        .cover .img{transform:scale(1.2);transition:all 0.8s;}
      }
    }
    .cover{margin:0 5px 5px;height:200px;overflow:hidden;border-radius:4px;}
    .title{
      line-height:50px;text-align:center;
      span{
        display:inline-block;vertical-align:middle;
        &:first-child{max-width:80%;padding-right:5px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
      }
    }
  }
</style>
