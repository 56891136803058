<template>
  <div class="comment-list">
    <comment-item v-for="item of list" :datas="item" :key="item.commentId"/>
    <empty v-if="isEmpty" :title="$t('NoData')"/>
    <el-pagination class="pages"
                   background
                   layout="prev, pager, next"
                   :page-count="totalPages"
                   @current-change="changePage"
                   hide-on-single-page>
    </el-pagination>
  </div>
</template>
<script>
  import Empty from '../../../components/Empty';
  import CommentItem from '../../../components/CommentItem';
  import {getProductCommentList} from '../../../api/product';

  export default {
    name: 'CommentList',
    data() {
      return {
        productId: '',
        list: [],
        totalPages: 1,
        isEmpty: false
      };
    },
    components: {Empty, CommentItem},
    created() {
      this.productId = this.$route.params.id;
      this.getList();
    },
    methods: {
      // 获取评论列表
      getList(page = 1) {
        const data = {page, perPage: 6};
        getProductCommentList(this.productId, data).then(res => {
          const {value} = res;
          const list = value.list || [];
          this.list = list;
          this.totalPages = value.totalPages;
          this.isEmpty = data.page === 1 && !this.list.length;
        });
      },
      changePage(page) {
        this.getList(page);
      }
    }
  };
</script>
<style scoped lang="less">
  .comment-list{padding-bottom:30px;}
</style>
