<template>
 <el-row :gutter=10>
  <div class="cl" v-if="mediaList.length">
    <el-col :span="16" :xs="24">
    <div class="album-scoll">
      <img class="img" :src="bigImg" @click="isShowPopup=true"/>
      <a class="a-btn btn-prev" @click="mediaListIndex--" v-if="mediaListIndex>0"></a>
      <a class="a-btn btn-next" @click="mediaListIndex++" v-if="mediaListIndex<mediaList.length-1"></a>
    </div>
    </el-col>
    <el-col :span="8" :xs="24">
    <div class="album-list">
      <div class="cl">
        <a class="item"
           v-for="(item,index) of mediaList"
           :class="{'is-current':index===mediaListIndex}"
           :key="item.mediaId"
           @click="mediaListIndex=index">
          <img class="img" :src="item.photoUrl" alt=""/>
        </a>
      </div>
    </div>
    </el-col>
    <pictures-popup :is-show.sync="isShowPopup" :list="mediaList" :index.sync="mediaListIndex"/>
  </div>
 </el-row>
</template>
<script>
  import PicturesPopup from './PicturesPopup';
  import {getProductMediaList} from '../../../api/product';

  export default {
    name: 'Pictures',
    components: {PicturesPopup},
    data() {
      return {
        mediaList: [],
        mediaListIndex: 0,
        isShowPopup: false
      };
    },
    computed: {
      bigImg: function () {
        return this.mediaList[this.mediaListIndex].photoUrl;
      }
    },
    created() {
      this.getProductMediaList();
    },
    methods: {
      getProductMediaList() {
        getProductMediaList(this.$route.params.id).then(res => {
          const list = res.value || [];
          for (let i in list) {
            if (list[i].isDefault === 'Y') {
              this.mediaListIndex = Number(i);
              break;
            }
          }
          this.mediaList = list;
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .album-scoll{
    position:relative;
    .img{cursor:pointer;}
    .a-btn{position:absolute;top:50%;width:24px;height:41px;margin-top:-21px;cursor:pointer;}
    .btn-prev{left:20px;background:url(../../../assets/images/btn-prev-white.png);}
    .btn-next{right:20px;background:url(../../../assets/images/btn-next-white.png);}
  }
  .album-list{
    overflow:auto;
    -ms-scroll-chaining:chained;
    -ms-overflow-style:none;
    -ms-content-zooming:zoom;
    -ms-scroll-rails:none;
    -ms-content-zoom-limit-min:100%;
    -ms-content-zoom-limit-max:500%;
    -ms-scroll-snap-type:proximity;
    -ms-scroll-snap-points-x:snapList(100%, 200%, 300%, 400%, 500%);
    -ms-overflow-style:none;
    &::-webkit-scrollbar{width:0;height:0;}
    &::-webkit-scrollbar-thumb{background-color:rgba(0, 0, 0, 0);}
    .item{position:relative;float:left;width:100px;height:100px;cursor:pointer;margin: 5px;}
    .is-current{
      &:after{position:absolute;top:0;left:0;right:0;bottom:0;border:2px solid #ff6f61;content:'';}
    }
  }
</style>
