import { render, staticRenderFns } from "./CommentItem.vue?vue&type=template&id=25e85308&scoped=true"
import script from "./CommentItem.vue?vue&type=script&lang=js"
export * from "./CommentItem.vue?vue&type=script&lang=js"
import style0 from "./CommentItem.vue?vue&type=style&index=0&id=25e85308&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25e85308",
  null
  
)

export default component.exports