<template>
  <div class="calendar">
    <div class="hd">
      <a class="btn-prev" :class="{disabled:isHideBtnPrev}" @click="changeMonth(-1)">
        <i class="el-icon-arrow-left"></i>
      </a>
      <a class="btn-next" :class="{disabled:isHideBtnNext}" @click="changeMonth(1)">
        <i class="el-icon-arrow-right"></i>
      </a>
      <div class="month">{{year}} - {{month}}</div>
    </div>
    <div class="bd">
      <table class="calendar-table">
        <thead>
        <tr>
          <th v-for="(value,name) of $t('el').datepicker.weeks" :key="name">{{value}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(week,index) of monthPrice" :key="index">
          <td v-for="item of week"
              :key="item.ts"
              :class="{'is-link':item.curMonth===0,'is-today':item.today,'is-selected':item.dateStr===dateStr&&item.curMonth===0,'disabled':item.isTopUp === 'Y'||!item.productId}"
              @click="selectedDate(item)">
            <template v-if="item.curMonth===0">
              <el-tooltip popper-class="line-remaining" content="Bottom center" placement="bottom" effect="light"
                          :disabled="!item.priceDateStatuses||!item.priceDateStatuses.length">
                <div>
                  <div class="number">{{item.day}}</div>
                  <template v-if="checkLineExistThatDay(item)">
                    <div class="price">{{item.billingType|currencySymbol}}{{findProductPriceDate(item)}}+</div>
                    <div class="remaining">{{item|remaining}}</div>
                  </template>
                </div>
                <div slot="content">
                  <div class="line-item-info" v-for="lItem of item.priceDateStatuses" :key="lItem.lineId">
                    {{$t('Route')}}{{lItem.lineTitle}}：{{lItem.memo}}
                  </div>
                </div>
              </el-tooltip>
            </template>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
  import CalendarHelper from 'calendar-helper/index';
  import i18n from '../../../i18n';
  import {formatNumber} from '../../../utils';

  export default {
    name: 'Calendar',
    filters: {
      remaining: function (item) {
        if (item.isGroup === 'N') {
          return i18n.t('GroupStatus.not');
        } else {
          if (item.isTopUp === 'Y') {
            return i18n.t('GroupStatus.full');
          } else if (item.lastPeopleNumber > 9) {
            return `${i18n.t('GroupStatus.many', [item.lastPeopleNumber])}`;
          } else {
            return `${i18n.t('GroupStatus.less', [item.lastPeopleNumber])}`;
          }
        }
      }
    },
    props: {
      value: String,
      prices: Array,
      lineId: String
    },
    data() {
      return {
        minDate: new Date().getTime(),
        maxDate: new Date().getTime(),
        monthData: [],
        year: '',
        month: '',
        dateStr: ''
      };
    },
    computed: {
      isHideBtnPrev: function () {
        const minDate = new Date(this.minDate);
        const year = minDate.getFullYear();
        const month = minDate.getMonth() + 1;
        return this.year === year && Number(this.month) === month;
      },
      isHideBtnNext: function () {
        const maxDate = new Date(this.maxDate);
        const year = maxDate.getFullYear();
        const month = maxDate.getMonth() + 1;
        return this.year === year && Number(this.month) === month;
      },
      monthPrice: function () {
        return this.monthData.map(weekItem => {
          const weeks = JSON.parse(JSON.stringify(weekItem));
          return weeks.map(item => {
            if (item.curMonth === 0) {
              const dateStr = item.dateStr;
              const price = this.prices.find(priceItem => priceItem.priceDate === dateStr) || {};
              Object.assign(item, price);
              return item;
            } else {
              return {dateStr: item.dateStr};
            }
          });
        });
      }
    },
    mounted() {
      this.setDefaultValue();
    },
    methods: {
      // Return Boolean by checking if line exist on user selected day
      checkLineExistThatDay: function (item) {
        let result = false;
        if (item.priceDateStatuses != null){
          item.priceDateStatuses.forEach((priceDate, index) => {
            if (priceDate.lineId === this.lineId) {
              result = true;
            }
          });
        }
        return result;
      },
      // Used to find the product's line price
      findProductPriceDate: function (item) {
        let price = '';
        item.priceDateStatuses.forEach((priceDate, index) => {
          if (priceDate.lineId === this.lineId) {
            price = priceDate.productPrice;
          }
        });
        return price;
      },
      setDefaultValue() {
        const now = new Date();
        this.setYearMonth(now.getFullYear(), now.getMonth() + 1);
      },
      setYearMonth(year, month) {
        this.year = Number(year);
        this.month = formatNumber(month);
        this.getMonthData();
      },
      changeMonth(val) {
        let month = ~~this.month + val;
        if (month > 12) {
          this.year += 1;
          month = 1;
        } else if (month === 0) {
          this.year -= 1;
          month = 12;
        }
        this.month = formatNumber(month);
        this.getMonthData();
      },
      getMonthData() {
        const date = new Date(`${this.year}/${this.month}/01`);
        this.monthData = CalendarHelper.getMonthData(date, new Date());
      },
      selectedDate(data) {
        if (data.isTopUp === 'N' && data.productId) {
          this.dateStr = data.dateStr;
          this.$emit('input', data.dateStr);
        }
      }
    },
    watch: {
      value: function (value) {
        this.dateStr = value;
        if (value) {
          const dateArr = value.split('-');
          this.setYearMonth(dateArr[0], dateArr[1]);
        }
      },
      prices: function (value) {
        if (value && value.length) {
          const minDate = value[0].priceDate;
          const maxDate = value[value.length - 1].priceDate;
          const dateArr = minDate.split('-');
          this.minDate = new Date(minDate.replace(/-/g, '/')).getTime();
          this.maxDate = new Date(maxDate.replace(/-/g, '/')).getTime();
          this.setYearMonth(dateArr[0], dateArr[1]);
          this.$emit('input', minDate);
        } else {
          this.minDate = new Date().getTime();
          this.maxDate = new Date().getTime();
          this.setDefaultValue();
          this.$emit('input', '');
        }
      }
    }
  };
</script>
<style scoped lang="less">
  .calendar{
    user-select:none;
    .hd{line-height:48px;text-align:center;}
    .btn-prev,
    .btn-next{
      width:14.28571%;font-size:20px;cursor:pointer;
      &.disabled{display:none;}
    }
    .btn-prev{float:left;}
    .btn-next{float:right;}
    .month{margin:0 15%;color:#333;font-size:18px;}
  }
  .calendar-table{
    width:100%;
    th,
    td{height:48px;color:#333;}
    th{width:14.28571%;font-weight:normal;}
    td{
      position:relative;height:66px;line-height:1;text-align:center;
      &.is-today{color:#ff6f61;}
      &.is-link{
        cursor:pointer;
        &:hover:not(.disabled){background-color:rgba(255, 111, 97, 0.2);}
        &.disabled{
          color:#aaa;cursor:not-allowed;
          .price,
          .remaining{color:#aaa;}
        }
      }
      &.is-selected{
        color:#fff !important;background-color:#ff6f61 !important;
        .price,
        .remaining{color:#fff;}
      }
      .number{padding-top:10px;}
      .price{margin-top:5px;color:#ff6f61;font-size:12px;}
      .remaining{margin-top:5px;color:#666;font-size:12px;}
    }
  }
</style>
<style lang="less">
  .el-tooltip__popper.is-light.line-remaining{
    border-color:#ffd4d0;
    &[x-placement^=bottom] .popper__arrow{border-bottom-color:#ffd4d0;}
    &[x-placement^=top] .popper__arrow{border-top-color:#ffd4d0;}
    .line-item-info{
      margin-top:5px;white-space:nowrap;
      &:first-child{margin-top:0;}
    }
  }
</style>
